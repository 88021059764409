import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { RedocStandalone } from "redoc";

function App() {
    return (
        <div className="App">
            <RedocStandalone specUrl={process.env.REACT_APP_BACKEND_URL} />
        </div>
    );
}

export default App;
